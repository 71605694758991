

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody, Card, Accordion, } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../redux/actions/User";
import { addNewSite, getAllSiteList } from "../../redux/actions/Site";
import { getAllTags, getAllFolders } from "../../redux/actions/Get";
import { getTagForFIlters } from "../../redux/actions/Tag";

import CheckboxTree from 'react-checkbox-tree';
import { getDataAndFilter } from "../../utils/MyFilter"
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";

// services 
import { _ViewPrticularUser, _getProfileType } from "../../config/api/UserService";

// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"
import { Select, Icon } from 'antd';

import AddFolder from "../add_folder_common/AddFolderTag";

// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";






var actionArr = []
const { Option } = Select;


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this site',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,




            name: '',
            description: '',
            address: '',
            street: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',

            //validation fields  
            nameError: '',
            descriptionError: '',
            addressError: '',
            streetError: '',
            cityError: '',
            stateError: '',
            longitudeError: '',
            latitudeError: '',
            userId: '',


            mapContent: '',
            mapContentError: '',

            folderIdError: '',
            folderId: '',

            fileName: 'Choose file...',
            fileUploadFileError: '',
            coverImg: '',
            sortTag: '',
            showTagName: 'All Tag',
            showTagColor: '#ffffff',
            dataShow: false,


            userId: [],
            clientId: [],
            clientName: '',
            clientIdError: '',
            clientUserList: [],
            extraUserData: [],



            showProfileModal: false,
            nodeData: [],
            checked: [],
            expanded: [],
            profileError: '',
            toggleState:false











        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.onSelectClientGetUsers()
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getAllUserList({ status: "1" });
        let req = {
            folderType: 2
        }
        this.props.action.getAllFolders(req)
        this.props.action.getTagForFIlters({ tagType: 2 })

        this.getDataProfileType()



    }

    getDataProfileType = async () => {


        let resp = await _Api(Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "", 4)

        let arr1 = await getDataAndFilter(resp.responseData.profileInfo, 1)
        let arr2 = await getDataAndFilter(resp.responseData.profileInfo, 2)
        let arr3 = await getDataAndFilter(resp.responseData.profileInfo, 3)
        let arr4 = await getDataAndFilter(resp.responseData.profileInfo, 4)
        let arr5 = await getDataAndFilter(resp.responseData.profileInfo, 5)
        let arr6 = await getDataAndFilter(resp.responseData.profileInfo, 6)


        let fnArr = arr1.map((item1, index1) => (
            {
                value: `${item1._id}`,
                label: item1.levelName,
                isChecked: false,
                isOther: item1.levelName == "OTHER",
                inputValue: '',
                children: arr2.filter(item2 => item2.parentLevel == item1._id).map((data, index2) => ({

                    value: `${data._id}`,
                    label: data.levelName,
                    isChecked: false,
                    isOther: data.levelName == "OTHER",
                    inputValue: '',
                    children: arr3.filter(item3 => item3.parentLevel == data._id).map((data2, index3) => ({

                        value: `${data2._id} `,
                        label: data2.levelName,
                        isChecked: false,
                        isOther: data2.levelName == "OTHER",
                        inputValue: '',
                        children: arr4.filter(item4 => item4.parentLevel == data2._id).map((data3, index4) => ({

                            value: `${data3._id} `,
                            label: data3.levelName,
                            isChecked: false,
                            isOther: data3.levelName == "OTHER",
                            inputValue: '',
                            children: arr5.filter(item5 => item5.parentLevel == data3._id).map((data4, index5) => ({

                                value: `${data4._id} `,
                                label: data4.levelName,
                                isChecked: false,
                                isOther: data4.levelName == "OTHER",
                                inputValue: '',
                                children: arr6.filter(item6 => item6.parentLevel == data4._id).map((data5, index5) => ({

                                    value: `${data5._id} `,
                                    label: data5.levelName,
                                    isChecked: false,
                                    isOther: data5.levelName == "OTHER",
                                    inputValue: '',
                                }))
                            }))
                        }))
                    }))
                }))


            }
        ))

        setTimeout(() => {
            fnArr.forEach((item, index) => {

                if (item.children.length == 0) {
                    delete fnArr[index].children
                }

                if (item.children)
                    item.children.forEach((item2, index2) => {
                        if (item2.children.length == 0) {
                            delete fnArr[index].children[index2].children
                        }
                        else {
                            if (item2.children)
                                item2.children.forEach((item3, index3) => {
                                    if (item3.children.length == 0) {
                                        delete fnArr[index].children[index2].children[index3].children
                                    }
                                    else {

                                        if (item3.children)
                                            item3.children.forEach((item4, index4) => {
                                                if (item4.children.length == 0) {
                                                    delete fnArr[index].children[index2].children[index3].children[index4].children
                                                }
                                                else {

                                                    if (item4.children)
                                                        item4.children.forEach((item5, index5) => {
                                                            if (item5.children.length == 0) {
                                                                delete fnArr[index].children[index2].children[index3].children[index4].children[index5].children
                                                            }
                                                        })
                                                }
                                            })
                                    }


                                })


                        }
                    })
            })

            this.setState({ nodeData: fnArr, isSpinner:false })

        }, 3000)



    }
    
    handleProfileModal = () => {
        this.setState({ showProfileModal: false, inviteNewPopupOpen:true })
    }



    onSubmitCheck = () => {
   
        this.setState({ showProfileModal: false, inviteNewPopupOpen:true })

    }

    handleSecleted = (e) => {
        this.setState({ checked: e })
    }



    



    //handle on handleOnChange
    handleChangeAll = (e) => {
        this.setState({ [e.target.name]: e.target.value, nameError: '', descriptionError: '', addressError: '', streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '', mapContentError: '', profileError: '', });

    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {

        this.setState({
            userId: [],
            checked:[],
            // clientUserList: [],
            extraUserData: [],
            fileName: 'Choose file...',
            tagId: '',
            name: '', description: '',
            address: '', street: '', city: '',
            state: '', latitude: '', longitude: '',
            nameError: '', descriptionError: '', addressError: '',
            streetError: '', cityError: '', stateError: '', latitudeError: '', longitudeError: '',
            folderIdError: '',
            // folderId:'',
            latitudeError: '',
            longitudeError: '',
            profileError:''
        })
        this.getDataProfileType()

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        let { name, description, address, street, city, state, latitude, longitude } = this.state;

        if (name == "") {
            this.setState({ nameError: "*Please enter name." })
        }
        else if (description == "") {
            this.setState({ descriptionError: "*Please enter description." })
        }
        else if (this.state.coverImg == "") {

            this.setState({ fileUploadFileError: "*Please add a  cover image.", })
        }

        else if (this.state.coverImg != "" && this.state.coverImg.type.split("/")[0] != "image") {

            this.setState({ fileUploadFileError: "*Please add a valid cover image.", })
        }
        else if (this.state.folderId == "") {
            this.setState({ folderIdError: "*Please select folder" })
        }
        else if (this.state.checked.length == 0) {
            this.setState({ profileError: "*Please add profile." })
        }


        else {

            let formData = new FormData()
            formData.append("siteName", name);
            formData.append("desc", description)
            formData.append("project", this.props.match.params.id)
            formData.append("folder", this.state.folderId)
            formData.append("tag", this.state.tagId)
            formData.append("cover", this.state.coverImg)
            formData.append("city", city)
            formData.append("address", address)
            formData.append("state", state)
            formData.append("lat", latitude)
            formData.append("long", longitude)
            formData.append("street", street)
            formData.append("code", this.state.mapContent)
            formData.append("projectType", 'S')
            formData.append("clientId", this.state.clientId);
            formData.append("user", this.state.userId);
            formData.append("profile",JSON.stringify(this.state.nodeData))


            let req2 = {
                project: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
                tag: this.state.sortTag,
                tagType: 2,
                folder: '',
            }




       


            if (e.target.name == "save") {

                this.props.action.addNewSite(formData, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewSite(formData, req2)
                this.topInp.focus();
                this.clearFields();
            }
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this site" : status == 0 ? "Inactive this site" : "delete this site" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            siteId: [actionArrr].toString()
        }

        _Api(Url.ADMIN_CHANGE_STATUS_SITE.method, Url.ADMIN_CHANGE_STATUS_SITE.url, req  )
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })

            

                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditsites = (user) => {

        this.props.history.push(`sites/${user._id}`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }
    // on image upload
    onImageUpload = e => {
        let file = e.target.files[0]
        this.setState({ coverImg: file, fileName: file.name, fileUploadFileError: '' })
    }




    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (Math.ceil(this.props.dataCount / this.state.dataCount)) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            project: this.props.match.params.id,
            // project:this.props.match.params.id,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
            tag: this.state.sortTag
        }



        this.props.action.getAllSiteList(req);
    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'siteName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = (e) => {

        if (e.target.name == "tag")
            this.clickChild()


        else if (e.target.name == "user")
            this.clickChild2()

        else if (e.target.name == "folder")
            this.clickChild1()



        this.setState({ inviteNewPopupOpen: false })


    }


    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }

    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
  

    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these sites" : status == 0 ? "Inactive these sites" : "delete these sites" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.siteInfo &&
                    this.props.siteInfo.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }



    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }

    sortByTag = (item) => {

        this.setState({
            sortTag: item._id,
            showTagName: item.tagName,
            showTagColor: item.color
        }, () => this.onClickPagenation())

    }



    handleOnSelect = (data) => {
        if (this.state.userId.indexOf(data) == -1) {
            this.setState({ userId: [...this.state.userId, data] })

        }
        else if (this.state.userId.indexOf(data) != -1) {
            this.setState({ userId: this.state.userId.filter(item => item != data) })
        }

    }


    handleChangeMultiSelect = (value) => {

    }

    onSelectClientGetUsers = () => {
        try {
            
            if (this.props.match.params.id) {
            
                let req = { projectId: this.props.match.params.id, }
                _Api(Url.ADMIN_VIEW_PROJECT.method, Url.ADMIN_VIEW_PROJECT.url, req, this.props.match.params.id  ) 
                    .then(resp => {
                      
                        this.setState({ clientName: resp.responseData.projectData.clientId.clientName, clientId: resp.responseData.projectData.clientId._id, extraUserData: resp.responseData.userDetails }, () => this.getClientsUsers())

                    })
                    .catch(err => {
                     

                    })
            }
        }
        catch (err) { }
    }


    getClientsUsers = () => {
        try {
            if (this.state.clientId) {
                let req = { clientId: this.state.clientId, projectId: this.props.match.params.id, status: "1", role: "3" }
                _ViewPrticularUser(req)
                    .then(resp => {                     

                        this.setState({ clientUserList: resp.responseData.usersInfo, isLoadingState: false })
                    })
                    .catch(err => {

                    })
            }
        }
        catch (err) { }
    }



    onSelectProject = (e) => {
        try {
            this.setState({ projectId: e.target.value, userId: [], clientName: '', isLoadingState: true }, () => {
                this.onSelectClientGetUsers()

            })

        } catch (err) {

        }

    }

    handleCheckedBox = (item, index = '', index2='') => {
        item.isChecked = !item.isChecked
        if(item.children){
            item.children.forEach(item2=>{ 
                item2.isChecked = item2.isOther ? item2.isChecked : !item2.isChecked
                if(item2.children){
                    item2.children.forEach(item3=>{ 
                        item3.isChecked = item3.isOther ? item3.isChecked : !item3.isChecked
                        if(item3.children){
                            item3.children.forEach(item4=>{ 
                                item4.isChecked = item4.isOther ? item4.isChecked : !item4.isChecked
                                if(item4.children){
                                    item4.children.forEach(item5=>{ 
                                        item5.isChecked = item5.isOther ? item5.isChecked : !item5.isChecked
                                        
                                    
                                    } )
                                    
                                }
                                
                            
                            } )
                            
                        }
                    
                    
                    } )
                    
                }
            
            } )
            
        }
        this.setState({toggleState:!this.state.toggleState})  
        if( this.state.checked.indexOf(item.value) == -1)
        this.state.checked.push(item.value)       
    }

    handleOtherInput = (e, item, index) => {
        item.inputValue = e.target.value
        this.setState({toggleState:!this.state.toggleState})
    }



    render() {
        let { name, description, address, street, city, state, latitude, longitude, nameError, descriptionError, addressError, streetError, cityError, stateError, latitudeError, longitudeError } = this.state;

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        setTimeout(() => this.setState({ dataShow: true }), 2000)


        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}

                {/* <div className="body_header folder">
                    {/* <h6 className="headingH6"> <Link to="/resources/folders">Resources</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}


                <div className="body_header ">
                    {/* <h6 className="headingH6" > <Link to="/sites">SITES</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}
                    <div className="new_btn text-right btn_parent">
                        <div className="btn_child">
                            <Dropdown className="filter_dropdown1 btn btn-sm" alignRight>
                                <Dropdown.Toggle className="ext-css" variant="success" id="dropdown-basic"  >
                                    <>

                                        <div style={{ backgroundColor: this.state.showTagColor }} className="filter_dropdown_option"></div>
                                        &nbsp;&nbsp;
                                <div className="tagText">{this.state.showTagName}</div>
                                    </>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="action_menu" alignRight>
                                    <>
                                        <Dropdown.Item onSelect={() => this.sortByTag({ _id: '', tagName: 'All Tag', color: '#ffffff' })}  >
                                            <div className="filter_dropdown_parent" >
                                                {/* <div style={{ backgroundColor: 'white' }} className="filter_dropdown_option"></div> */}
                                                &nbsp;&nbsp;
                                                <div>All Tag
                                                </div>
                                            </div>

                                        </Dropdown.Item>

                                    </>
                                    {
                                        this.props.filterTagData && this.props.filterTagData.map((item, index) => (
                                            <>
                                                <Dropdown.Item  >
                                                    <div className="filter_dropdown_parent" onClick={() => this.sortByTag(item)}>

                                                        <div style={{ backgroundColor: item.color }} className="filter_dropdown_option"></div>
                                                        &nbsp;&nbsp; <div>{item.tagName}</div>
                                                    </div>

                                                </Dropdown.Item>

                                            </>

                                        ))
                                    }

                                </Dropdown.Menu>
                            </Dropdown>
                            <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={this.openInviteNewPopupOpen}>Create New Site</button>
                            {/* &nbsp; &nbsp; <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.props.history.push("sites/data")}>Import</button> */}
                        </div>
                    </div>
                </div>
                <div className="counter_detail">

                    {
                        actionArr && actionArr.length != 0 &&

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                                {actionArr.length} Select
                          </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu">
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "1")}>Active</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "0")}>Inactive</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "2")}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>




                <div className="table-responsive">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>

                                <th>Sr No.</th>
                                <th className="cur_pointer" onClick={() => this.handleOnClickSort("firstName")}>Site Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                <th >Client</th>
                                <th>ProjectName</th>
                                <th className="cur_pointer" onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                <th>Tag</th>
                                <th>Action</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                this.props.siteInfo &&
                                this.props.siteInfo.map((sites, index) => (
                                    <tr key={index}>
                                        <td><input type="checkbox" className="checkbox" name="time" value={sites._id} checked={actionArr.indexOf(sites._id) != -1} onClick={this.handleChecked} /></td>

                                        <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                        <td className="txtClassUpper" >{`${sites.siteName}`}</td>
                                        <td>{sites.userName == undefined ? <><i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;</> : sites.userName}</td>
                                        <td>{sites.projectName == undefined ? <><i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;<i class="fa fa-window-minimize" aria-hidden="true"></i> &nbsp;</> : sites.projectName}</td>
                                        <td>{dateFormat(sites.created, "mm/dd/yyyy")}</td>
                                        <td>
                                            {
                                                sites.tagName == undefined ?
                                                    <>
                                                        <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                        &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                        &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                    </>
                                                    :
                                                    <div class="display-flex">
                                                        <div style={{ backgroundColor: sites.color }} class="color_circle"></div>
                                                        &nbsp;&nbsp;
                                                                                <div>{sites.tagName}</div>
                                                    </div>
                                            }
                                        </td>
                                        <td>
                                            <div>
                                                <button type="submit" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onClickEditsites(sites)}>Edit</button>&nbsp;&nbsp;&nbsp;
                                                    <button type="submit" className="btn btn-primary btn-sm btn-pink" onClick={() => this.handleDeleteModal(sites._id, "2")}>Delete</button>
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" onClick={() => this.handleDeleteModal(sites._id, sites.status == "1" ? "0" : "1")} class={`btn btn-toggle ${sites.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autocomplete="off">
                                                <div class="handle"></div>
                                            </button>
                                        </td>

                                        {/* <td>{user.status == 1 ? <span className="cm_active" onClick = {()=> this.onUserAction("0", user._id)}>Active</span> : <span style={{ color: 'red' }} className="cm_active" onClick = {()=> this.onUserAction("1", user._id)}>InActive</span>}</td> */}
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        this.state.dataShow && this.props.dataCount == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="cm_center_text"  > Sorry no more content </p>
                    }





                    <div className="float-right">
                        {this.props.dataCount >= 10 &&
                            <div class="pagination">
                                <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.dataCount / this.state.dataCount) <= this.state.pageNo + 1} ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>

                            </div>

                        }
                    </div>
                </div>


                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />
                <AddFolder
                    folderType={2}
                    setClick={click => this.clickChild1 = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                <div>
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal">
                        <Modal.Header closeButton>
                            {/* {/ <Modal.Title>Modal heading</Modal.Title> /} */}
                            <h6>Create New Site</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form >

                                <div class="form-group">
                                    <label for="=Name"> Name</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.nameError != '' ? 'is-invalid' : ''}`}
                                        id="Name"
                                        placeholder="Name"
                                        ref={(inp) => this.topInp = inp}
                                        maxLength={30}
                                        name='name'
                                        value={name}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={nameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="Description">Description</label><span className="impFields">*</span>
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.descriptionError != '' ? 'is-invalid' : ''}`}
                                        id="reportDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name='description'
                                        value={description}
                                        onChange={this.handleChangeAll}

                                    />

                                    <InlineError
                                        message={descriptionError}
                                    />
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Cover image</label><span className="impFields">*</span><br />
                                        <div class="custom-file">
                                            <input id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}

                                                accept="image/png,image/jpeg"
                                            />
                                            <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                        </div>
                                        <InlineError
                                            message={this.state.fileUploadFileError}
                                        />
                                    </div>
                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Client</label><span className="impFields">*</span>
                                        <input type="text"
                                            className={`form-control`}
                                            id="Name"
                                            value={this.state.clientName}

                                            disabled
                                        />
                                    </div>


                                </div>
                                <br />
                                <br />
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Users</label><br />
                                        <Select
                                            mode="multiple"
                                            placeholder="Please select users"
                                            // defaultValue={}
                                            value={this.state.userId}
                                            onChange={this.handleChangeMultiSelect}
                                            onSelect={(i) => this.handleOnSelect(i)}
                                            onDeselect={(i) => this.handleOnSelect(i)}
                                        >
                                            {this.state.clientUserList.map((item, index) => (
                                                <Option value={item._id} key={index}>{`${item.firstName} ${item.lastName}`}</Option>

                                            ))}
                                        </Select>
                                    </div>

                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleChangeAll}>
                                            {this.state.tagId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>}
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" name="tag" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>


                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Folder</label><span className="impFields">*</span><br />
                                        <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                            {this.state.folderId == "" && <Option key={-1} value={"select"} disabled selected hidden  >{"Select user"}</Option>}
                                            {
                                                this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                    <Option value={item._id}>
                                                        <div class="dropdown_flex">
                                                            <div><img src={item.icon} /></div>
                                                            <div><p>{item.folderName}</p></div>
                                                        </div>
                                                    </Option>

                                                ))
                                            }

                                        </Select>
                                        <InlineError
                                            message={this.state.folderIdError}
                                        />

                                    </div>
                                    <div className="col-md-4"><button name="folder" type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Folder</button></div>

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="Address">Address</label>

                                        <textarea rows="4" cols="50"
                                            className={`form-control ${this.state.addressError != '' ? 'is-invalid' : ''}`}
                                            id="Address"
                                            placeholder="Enter Address"
                                            maxLength={256}
                                            name='address'
                                            value={address}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={addressError}
                                        />
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label for="Street">Street</label>
                                    <input type="text"
                                        className={`form-control ${this.state.streetError != '' ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="Street"
                                        placeholder="Street"
                                        name='street'
                                        value={street}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={streetError}
                                    />

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6">
                                        <label for="City">City</label>
                                        <input type="text"
                                            className={`form-control ${this.state.cityError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="City" placeholder="City"
                                            name='city'
                                            value={city}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={cityError}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label for="State">State</label>
                                        <input type="text"
                                            className={`form-control ${this.state.stateError != '' ? 'is-invalid' : ''}`}
                                            maxLength={50}
                                            id="State" placeholder="State"
                                            name='state'
                                            value={state}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={stateError}
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="email">Map content</label>
                                    <textarea rows="4" cols="50"
                                        className={`form-control ${this.state.mapContentError != '' ? 'is-invalid' : ''}`}
                                        id="email"

                                        placeholder="Map content"
                                        name="mapContent"
                                        autocomplete="off"
                                        value={this.state.mapContent}
                                        onChange={this.handleChangeAll}
                                    />
                                    <InlineError
                                        message={this.state.mapContentError}
                                    />

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-6 heih93">
                                        <label for="Latitude">Latitude</label>
                                        <input type="number"
                                            className={`form-control ${this.state.latitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Latitude" placeholder="Latitude"
                                            name='latitude'
                                            value={latitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={latitudeError}
                                        />
                                    </div>
                                    <div className="col-md-6 heih93">
                                        <label for="Longitude">Longitude</label>
                                        <input type="number"
                                            className={`form-control ${this.state.longitudeError != '' ? 'is-invalid' : ''}`}
                                            maxLength={30}
                                            id="Longitude" placeholder="Longitude"
                                            name='longitude'
                                            value={longitude}
                                            onChange={this.handleChangeAll}
                                        />
                                        <InlineError
                                            message={longitudeError}
                                        />
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button
                                        type="button"
                                        className={`btn btn-primary btn-block ${this.state.profileError != '' ? 'is-invalid' : ''}`}

                                        onClick={() => this.setState({ showProfileModal: true, inviteNewPopupOpen:false,profileError:'' })} >
                                        Add Profile
                                </button>
                                    <InlineError
                                        message={this.state.profileError}
                                    />
                                </div>



                                <br />
                                <div class="form-group text-center">

                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>


                            </form>
                        </Modal.Body>

                    </Modal>
                </div>
                <Modal show={this.state.showProfileModal} onHide={this.handleProfileModal} className="custom_modal treeview_modal" >
                    <Modal.Header closeButton>
                        {/* <h6>Profile</h6> */}
                    </Modal.Header>
                    <Modal.Body>
                        <h6>Site Profile</h6>
                        <Accordion defaultActiveKey="00000000">
                            {
                                this.state.nodeData.map((item, index) => (
                                    <Card>
                                        <Card.Header className="cm_accordion_header">
                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`} >
                                                {item.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item.isChecked} onClick={() => this.handleCheckedBox(item)} name="vehicle1" value="Bike" /> {item.label}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={`${index}`}>
                                            <Card.Body className="cm_accordion_body">
                                                {
                                                    item.children && item.children.map((item2, index2) => (
                                                        <Accordion defaultActiveKey="00000">
                                                            <Card.Header className="cm_accordion_header">
                                                                <Accordion.Toggle as={Button} variant="link" eventKey={`${index2}`} >
                                                                    {item2.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item2.isChecked} onClick={() => this.handleCheckedBox(item2)} name="vehicle1" value="Bike" />


                                                                    <span>{item2.label} </span>
                                                                    {item2.isOther && item2.isChecked &&
                                                                        <>
                                                                            <input type="text" value = {item2.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item2)} />
                                                                        </>
                                                                    }
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={`${index2}`}>
                                                                <Card.Body className="cm_accordion_body">
                                                                    {
                                                                        item2.children && item2.children.map((item3, index3) => (
                                                                            <Accordion defaultActiveKey="0000">

                                                                                <Card.Header className="cm_accordion_header">
                                                                                    <Accordion.Toggle as={Button} variant="link" eventKey={`${index3}`} >
                                                                                        {item3.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item3.isChecked} onClick={() => this.handleCheckedBox(item3)} name="vehicle1" value="Bike" />
                                                                                        <span>{item3.label} </span>
                                                                                        {item3.isOther && item3.isChecked &&
                                                                                            <>
                                                                                               <input type="text" value = {item3.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item3)} />
                                                                                            </>
                                                                                        }

                                                                                    </Accordion.Toggle>
                                                                                </Card.Header>
                                                                                <Accordion.Collapse eventKey={`${index3}`}>
                                                                                    <Card.Body className="cm_accordion_body">
                                                                                        {
                                                                                            item3.children && item3.children.map((item4, index4) => (
                                                                                                <Accordion defaultActiveKey="0000">

                                                                                                    <Card.Header className="cm_accordion_header">
                                                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={`${index4}`} >
                                                                                                            {item4.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item4.isChecked} onClick={() => this.handleCheckedBox(item4)} name="vehicle1" value="Bike" />


                                                                                                            <span>{item4.label} </span>
                                                                                                            {item4.isOther && item4.isChecked &&
                                                                                                                <>
                                                                                                                    <input type="text" value = {item4.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item4)} />
                                                                                                                </>
                                                                                                            }
                                                                                                        </Accordion.Toggle>
                                                                                                    </Card.Header>
                                                                                                    <Accordion.Collapse eventKey={`${index4}`}>
                                                                                                        <Card.Body className="cm_accordion_body">

                                                                                                            {
                                                                                                                item4.children && item4.children.map((item5, index5) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item5}`} >
                                                                                                                                {item5.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item5.isChecked} onClick={() => this.handleCheckedBox(item5)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item5.label} </span>
                                                                                                                                {item5.isOther && item5.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item5.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item5)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item5}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                            {
                                                                                                                item5.children && item5.children.map((item6, index6) => (
                                                                                                                    <Accordion defaultActiveKey="0000">

                                                                                                                        <Card.Header className="cm_accordion_header">
                                                                                                                            <Accordion.Toggle as={Button} variant="link" eventKey={`${item6}`} >
                                                                                                                                {item6.children && <i class="fa fa-chevron-right" aria-hidden="true"> </i>}<input type="checkbox" checked={item6.isChecked} onClick={() => this.handleCheckedBox(item6)} name="vehicle1" value="Bike" />



                                                                                                                                <span>{item6.label} </span>
                                                                                                                                {item6.isOther && item6.isChecked &&
                                                                                                                                    <>
                                                                                                                                      <input type="text" value = {item6.inputValue} placeholder = "Enter here..." className="form-control " onChange={(e) => this.handleOtherInput(e, item6)} />
                                                                                                                                    </>
                                                                                                                                }


                                                                                                                            </Accordion.Toggle>
                                                                                                                        </Card.Header>
                                                                                                                        <Accordion.Collapse eventKey={`${item6}`}>
                                                                                                                            <Card.Body className="cm_accordion_body">
                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                                                

                                                                                                                            </Card.Body>
                                                                                                                        </Accordion.Collapse>

                                                                                                                    </Accordion>

                                                                                                                ))
                                                                                                            }


                                                                                                        </Card.Body>
                                                                                                    </Accordion.Collapse>

                                                                                                </Accordion>

                                                                                            ))
                                                                                        }
                                                                                    </Card.Body>
                                                                                </Accordion.Collapse>

                                                                            </Accordion>

                                                                        ))
                                                                    }

                                                                </Card.Body>
                                                            </Accordion.Collapse>

                                                        </Accordion>

                                                    ))
                                                }

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))
                            }

                        </Accordion>
                        <br />
                        <div class="form-group">
                            <button type="button" onClick={this.onSubmitCheck} name="save" class="btn btn-primary btn-blue " >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal" >
                    <Modal.Header closeButton>
                        <h6>Site Action</h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-default btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button variant="primary" class="btn btn-primary btn-sm btn-blue " onClick={this.handleActionModal}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        usersInfo: state.users.userInfo,
        activeSiteDetails: state.sites.activeSite,
        inActiveSiteDetails: state.sites.inactiveSite,
        totalSiteDetails: state.sites.totalSite,
        dataCount: state.sites.dataCount,
        foldersList: state.folders.folderList,
        filterTagData: state.tags.filterInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getTagForFIlters, getAllFolders, getAllSiteList, getAllUserList, addNewSite, getAllTags, addNewUser }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

