

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link, NavLink } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllTaskList, addNewTask } from "../../redux/actions/Task";
import { getAllTags } from "../../redux/actions/Get";
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";
// validation
import { validateEmail, validateMobileNo1 } from "../../utils/Validation";

// services 
import { _actionForTASK,_editTask,_viewTask } from "../../config/api/TaskServices";

// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"

import DatePicker from "react-datepicker";



var actionArr = []

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this task',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,
            selectAll: false,


            taskDescription: '',
            _id:'',
            taskDescriptionError: '',
            taskName: '',
            taskNameError: '',
            taskStartDate: new Date(),
            taskStartDataError: '',
            taskEndDate: new Date(),
            taskEndDateError: '',
            dependencyTask: '',
            taskDependency: '',
            taskDependencyError: ''







        }
    }
    handleChange1 = date => {
        this.setState({
            taskStartDate: date
        });
    };
    handleChange2 = date => {
        this.setState({
            taskEndDate: date
        });
    };

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags();


      const {
        dependency,
        desc,
        endDate,
        startDate,
        
        taskName,
        _id,
      }  = this.props.location.state.state

      this.setState({
          _id, 
          taskDescription:desc,         
          taskName,    
          taskStartDate: new Date(startDate),
          taskEndDate: new Date(endDate),
          dependencyTask: dependency,

        })

      

      this.getTask()


    }

    getTask = ()=>{
        let req = {
            taskID:this.props.match.params.cId
        }
        _viewTask(req)
         .then(resp=>{         

         })
         .catch(err=>{})
    }





    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            taskNameError: '',
            taskDescriptionError: '',
            taskDependencyError: '',
            taskEndDateError: '',
            taskStartDataError: ''

        });
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({
            taskDescription: '',
            taskDescriptionError: '',
            taskName: '',
            taskNameError: '',
            taskStartDate: new Date(),
            taskStartDataError: '',
            taskEndDate: new Date(),
            taskEndDateError: '',
            dependencyTask: '',
            taskDependency: '',
            taskDependencyError: ''
        })

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        if (this.state.taskName == "") {
            this.setState({ taskNameError: "*Please enter task name." })
        }
        else if (this.state.taskDescription == "") {
            this.setState({ taskDescriptionError: "*Please enter description." })
        }
        else {

            let req = {
                taskId:this.state._id,
                taskName: this.state.taskName,
                desc: this.state.taskDescription,
                startDate: this.state.taskStartDate,
                endDate: this.state.taskEndDate,
                dependency: this.state.taskDependency,
                siteId: this.props.match.params.id

            }
          

         _editTask(req)
            .then(resp => {
                notify("success", resp.responseData.message)
                // this.getUserDetails()
                this.setState({ isLoadingState: false, })
                this.props.history.goBack(); 


            }).catch(err => {
                this.setState({ isLoadingState: false, })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)

            })

           
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this task" : status == 0 ? "Inactive this task" : "delete this task" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            taskId: [actionArrr].toString()
        }

        _actionForTASK(req)
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })

                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditUser = (user) => {
        this.props.history.push(`${this.props.match.params.id}/${user._id}/edit`, { state: user });


    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (Math.ceil(this.props.dataCount / this.state.dataCount)) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
        }

      



        this.props.action.getAllTaskList(req);
    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'firstName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })
   

    }

   


    handleChecked = (e) => {
        let num = e.target.value
    

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }



    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
    

    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these tasks" : status == 0 ? "Inactive these tasks" : "delete these tasks" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.taskList &&
                    this.props.taskList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }




    render() {

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <div className="row">
                        <div className="col-sm-8">


            
                <form>
                            
                                <div class="form-group">
                                    <label for="email">Task</label><span className="impFields">*</span>
                                    <input type="text"
                                        className={`form-control ${this.state.taskNameError != '' ? 'is-invalid' : ''}`}
                                        placeholder="Enter Task"
                                        name="taskName"
                                        maxLength={30}
                                        required
                                        onChange={this.handleOnChange}
                                        value={this.state.taskName}

                                    />
                                    <InlineError
                                        message={this.state.taskNameError}
                                    />


                                </div>
                                <div class="form-group">
                                    <label for="email">Description</label><span className="impFields">*</span>
                                    <textarea rows="4" cols="50"
                                        type="text"
                                        className={`form-control ${this.state.taskDescriptionError != '' ? 'is-invalid' : ''}`}
                                        id="taskDescription"
                                        placeholder="Enter description"
                                        maxLength={256}
                                        name="taskDescription"
                                        autocomplete="off"
                                        required
                                        value={this.state.taskDescription}
                                        onChange={this.handleOnChange}


                                    />
                                    <InlineError
                                        message={this.state.taskDescriptionError}
                                    />
                                </div>
                                <div class="form-group pos_rel">
                                    <label for="email">Start Date</label><br/>
                                    <DatePicker
                                        selected={this.state.taskStartDate}
                                        onChange={this.handleChange1} className="form-control"

                                    />
                                    <i class="fa fa-calendar fa_calender" aria-hidden="true"></i>

                                </div>
                                <div class="form-group pos_rel">
                                    <label for="email">End Date</label><br/>
                                    <DatePicker
                                        selected={this.state.taskEndDate} className="form-control"
                                        onChange={this.handleChange2}

                                    />
                                    <i class="fa fa-calendar fa_calender" aria-hidden="true"></i>
                                </div>
                                <div class="form-group ">
                                    <label >Dependecy</label>
                                    <select className="form-control" id="sel1" name="taskDependency" value={this.state.taskDependency} onChange={this.handleOnChange}> taskDependency
                                    <option     value={"select"} disabled selected hidden  >{"Select Dependecy"}</option>
                                        <option value={1}>1</option>
                                        <option   value={1}>2</option>
                                        <option  value={1}>3</option>
                                        <option  value={1}>4</option>
                                        <option  value={1}>5</option>


                                    </select>
                                </div>
                                <br />
                                <div class="form-group">
                                    <button type="button" class="btn btn-danger mR-20" onClick={()=> this.props.history.goBack()}>Back</button>
                                    <button type="button" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>
                            </form>
                            </div>
                            </div>
                   
              
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />

                
                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Task Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDeleteModal}>
                            No
          </Button>
                        <Button variant="primary" onClick={this.handleActionModal}>
                            Yes
          </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        taskList: state.tasks.taskInfo,
        usersInfo: state.users.userInfo,
        activeUserDetails: state.users.activeUser,
        inActiveUserDetails: state.users.inactiveUser,
        totalUserDetails: state.users.totalUser,
        dataCount: state.tasks.dataCount
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllTaskList, getAllTags, addNewTask }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

