import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import Select from 'react-select';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';

//react router dom
import { Link } from "react-router-dom";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList } from "../../redux/actions/User";
import { getAllTags } from "../../redux/actions/Get";

// validation
import { validateEmail, validateMobileNo1 } from "../../utils/Validation";

// services
import { _viewUser } from "../../config/api/UserService";
// services 
import { _actionForUser, _editUser } from "../../config/api/UserService";

// inline error
import InlineError from "../../components/common/InlineError";
// notify
import { notify } from "../../components/common/Toaster";
// loader
import Loader from "../common/Loader";
import { Rightarrow } from '../Svg';
const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];
class UserClientsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingState: true,
            inviteNewPopupOpen: false,
            selectedOption: '',
            created: "",
            email: "",
            firstName: "",
            folder: "",
            lastName: "",
            password: "",
            phoneNumber: "",
            profilePic: "",
            role: 1,
            status: 1,
            tags: "",
            _id: "",
            userEmailError: '',
            userFirstNameError: '',
            userLastNameError: '',
            userPhoneNumberError: '',
            showDeleteModal: false,
            userRole: '',
            folderName: '',
            showUserName: '',
            showTagNamee: '',

        }
    }

    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' })
    }


    handleChange = selectedOption => {
        this.setState({ selectedOption });
    }

    componentDidMount() {
        this.props.action.getAllUserList();
        this.props.action.getAllTags({ status: "1" })
        this.getUserDetails()

    }

    // getUser details
    getUserDetails = () => {
        try {
            if (this.props.match.params)
                _viewUser({ userId: this.props.match.params.id })
                    .then(resp => {
                        const { created, email, firstName, folder, lastName, password,
                            phoneNumber,
                            profilePic,
                            role,
                            status,
                            tags,
                            tagName,
                            folderName,
                            contactName,
                            clientName,
                            _id } = resp.responseData.userData
                            this.setState({
                            showTagNamee: tagName == undefined ? "" : tagName,
                            userLastName: contactName,
                            userFirstName: clientName,
                            showUserName: resp.responseData.userData.firstName,
                            isLoadingState: false, folderName, created, email,
                            folder, password, userPhoneNumber: phoneNumber,
                            roleType: role == 1 ? "Individual" : "Comprehensive", status, tags, _id
                        })

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }





    handleOnSubmitForm = (e) => {
        if (this.state.userFirstName == "") {
            this.setState({ userFirstNameError: "*Please enter  name." })
        }
        else if (this.state.userLastName == "") {
            this.setState({ userLastNameError: "*Please enter contact name." })
        }

        else if (this.state.userPhoneNumber == "") {
            this.setState({ userPhoneNumberError: "*Please enter phone number." })
        }
        else if (this.state.userEmail == "") {
            this.setState({ userEmailError: "*Please enter email." })
        }

        else if (!validateMobileNo1(this.state.phoneNumber).status) {
            this.setState({ userPhoneNumberError: "*Please enter valid phone number." })
        }
        else {

            this.onEditUser()
        }
    }



    // handle delete modal
    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal })
        if (id)
            this.setState({ deletUserId: id });
    }


    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (statusCode) => {
        try {
            this.setState({ showDeleteModal: false, isLoadingState: true })
            let req = {
                status: statusCode,
                activeId: this.state._id
            }

            _actionForUser(req)
                .then(resp => {
                    this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '' })
                    notify("success", "User delete successfully")
                    this.props.history.push(`/users/${this.props.match.params.folderId}`, { state: this.state.folderName });

                }).catch(err => {
                    this.setState({ isLoadingState: false, showDeleteModal: false })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)
                })

        } catch (error) {

        }
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    // close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
    }

    // on edit submit user
    onEditUser = () => {
        this.setState({ isLoadingState: true, })

        try {
            let req = {
                userId: this.state._id,
                clientName: this.state.userFirstName,
                contactName: this.state.userLastName,
                email: this.state.userEmail,
                phoneNumber: this.state.userPhoneNumber,

                role: this.state.roleType,
                folder: this.props.match.params.id,
                tags: this.state.tagId,


            }
            _editUser(req)
                .then(resp => {
                    notify("success", resp.responseData.message)
                    this.setState({ isLoadingState: false, })
                    this.props.history.push(`/clients/${this.props.match.params.folderId}`, { state: this.state.folderName });


                }).catch(err => {
                    this.setState({ isLoadingState: false, })

                    if (err.error)
                        notify("err", err.error.message)
                    else if (err)
                        notify("err", err.message)

                })


        } catch (err) {

        }
    }
    render() {
        const { selectedOption } = this.state;
        return (
            <>
                {this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header />
                    <div className="body_header folder">
                        <h6 className="headingH6"><Link to="/clients">Clients </Link>
                     
                        <span className="cm_arrow"><Rightarrow/></span>
                        &nbsp;
                        <Link to={{ pathname: `/clients/${this.props.match.params.folderId}`, state: { state: this.state.folderName } }}   >{this.state.folderName}</Link>&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp;{this.state.showUserName}</h6>
                    </div>
                    <div className="counter_detail">
                    </div>
                    <hr />                   
                    <br /><br />
                    <div className="row">
                        <div className="col-sm-8">
                            <form onSubmit={this.handleOnSubmitForm}>

                                <div class="form-group">
                                    <label for="email">Client Name </label><span className="impFields">*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${this.state.userFirstNameError != '' ? 'is-invalid' : ''}`}
                                        id="userFirstName"
                                        name="userFirstName"
                                        placeholder="Client Name"
                                        maxLength={20}
                                        value={this.state.userFirstName}
                                        autocomplete="off"
                                        onChange={this.handleOnChange}
                                        required

                                    />
                                    <InlineError
                                        message={this.state.userFirstNameError}
                                    />
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-12">
                                        <label for="email">Type</label>
                                        <select class="form-control" id="sel1" name="roleType" onChange={this.handleOnChange}>
                                            {this.state.roleType == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select type"}</option>}
                                            <option value={1}>{"Individual"}</option>
                                            <option value={2}>{"Comprehensive"}</option>
                                        </select>
                                    </div>
                                </div>
                                <br />
                                <div class="form-group">
                                    <label for="email">Contact Name </label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userLastNameError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Contact Name"
                                        maxLength={20}
                                        name="userLastName"
                                        autocomplete="off"
                                        required
                                        value={this.state.userLastName}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.userLastNameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Contact Email </label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userEmailError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        maxLength={50}
                                        placeholder="Contact Email"
                                        autocomplete="off"

                                        required
                                        name="userEmail"
                                        value={this.state.userEmail}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userEmailError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Contact Phone Number</label><span className="impFields">*</span>
                                    <input type="tel"
                                        className={`form-control ${this.state.userPhoneNumberError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        required
                                        maxLength={20}
                                        placeholder="Contact Phone Number"
                                        name="userPhoneNumber"
                                        autocomplete="off"
                                        value={this.state.userPhoneNumber}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userPhoneNumberError}
                                    />
                                </div>

                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleOnChange}>
                                            {this.state.tagId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>}
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <br />
                                <div class="form-group">
                                    <button type="button" class="btn btn-danger mR-20" onClick={this.handleDeleteModal}>Delete</button>
                                    <button type="button" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete User</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want delete this user ? </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button className="btn btn-primary btn-sm btn-blue " onClick={() => this.onUserAction("2")}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({ getAllUserList, getAllTags }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserClientsView);

