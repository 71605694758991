
import React from "react";

const reduxState = {

     AuthInitalState : {
        isLoggedIn: false,
        accessToken: '',
    
    
    },
    commonInitalState : {
        isLoading: false,
        isLoadingStatus:0
    
    },
    communityInitalState :{
        comInfo: [],
        activeCom:0,
        inactiveCom:0,
        totalCom:0,    
    },
    folderInitalState :{
        folderList:[]
    },

usersInitalState : {
    userInfo: [],
    activeUser:0,
    inactiveUser:0,
    totalUser:0,

},
tagsInitalState:{
    tagInfo: [],
    activeTag: 0,
    dataCount: 0,
    inactiveTag: 0,
    totalTag: 0,
    filterInfo:[],
    

},

resourcesInitalState : {
    resourceInfo: [],
    activeresource:0,
    inactiveResource:0,
    totalResource:0,
    dataCount:0,

},
reportsInitalState: {
    reportInfo: [],
    activeTag:0,
    inactiveTag:0,
    totalTag:0,

},

mediasInitalState: {
    mediaInfo: [],
    activeMedia:0,
    inactiveMedia:0,
    totalMedia:0,

},

mapsInitalState: {
    mapInfo: [],
    activeMap:0,
    inactiveMap:0,
    totalMap:0,
    dataCount:0,

},
sitsInitalState: {
    siteInfo: [],
    activeSite:0,
    inactiveSite:0,
    totalSite:0,
    dataCount:0,

},
projectInitalState:{
    projectInfo: [],
    activeProject:0,
    inactiveProject:0,
    totalProject:0,
    dataCount:0,

}
,
taskInitalState:{
    taskInfo: [],
    activeTask:0,
    inactiveTask:0,
    totalTask:0,
    dataCount:0,

}



    


}

export default reduxState