import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    GET_TAG_LIST_SUCCESS,
    GET_TAG_LIST_FAILURE,
    GET_FOLDER_SUCCESS

} from "./Types";


// notify
import { notify } from "../../components/common/Toaster";

// api fun
import { _getTags,_getFolders , _getSiteFolders,_getProjectFolders} from "../../config/api/GetServices";


// url
import Url from "../../config/env/BaseUrl";
import {_Api} from "../../config/api/Api";



// action for get tags
export const getAllTags = req => dispatch => {
    if(!req)return
    try {

    
        dispatch({ type: IS_LOADING_START })
        _Api(Url.ADMIN_GET_TAG.method ,Url.ADMIN_GET_TAG.url,req).then(resp => {
            let payload = {
                tagInfo: resp.responseData,

            }            
            dispatch({ type: GET_TAG_LIST_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
            
        })

    } catch (error) {
        notify("err", error.message)

    }

} 


// action for get tags
export const getAllFolders = req => dispatch => {
    try {

        dispatch({ type: IS_LOADING_START })
        _Api(Url.ADMIN_GET_FOLDER.method ,Url.ADMIN_GET_FOLDER.url,req).then(resp => {
            let payload = {
                folderList: resp.responseData,
                folderLoading:true
            }
            
            dispatch({ type: GET_FOLDER_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
        })

    } catch (error) {
        notify("err", error.message)

    }

}


// action for get tags
export const getSiteFolders = req => dispatch => {
    try {

        dispatch({ type: IS_LOADING_START })
        _getSiteFolders(req).then(resp => {
            let payload = {
                folderList: resp.responseData
            }            
            dispatch({ type: GET_FOLDER_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
          
        })

    } catch (error) {
        notify("err", error.message)

    }

}
// action for get tags
export const getProjectFolder = req => dispatch => {
    try {
        dispatch({ type: IS_LOADING_START })

        _getProjectFolders(req).then(resp => {
            let payload = {
                folderList: resp.responseData
            }            
            dispatch({ type: GET_FOLDER_SUCCESS, payload })
            dispatch({ type: IS_LOADING_STOP })
        }).catch(err => {
            dispatch({ type: IS_LOADING_STOP })
        })

    } catch (error) {
        notify("err", error.message)

    }

}