/**
 * @about this file used for routing inner client pages
 */

import React, { Component } from 'react';
import {Tabs, Tab } from 'react-bootstrap';
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// loader
import Loader from "../common/Loader";
// services

import { Rightarrow} from "../Svg";
// roucting lib
import {
    Router,
    Route,
    Switch,
    Link
} from 'react-router-dom';


// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";



// components & pages
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import Profile from "./Profile";
import Project from "./Project";
import Users from "./Users";
import UsersEdit from "./UserEdit";




// routes for inner clients pages
class ClientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showTab: '',
            clientName: '',
            typeClient:1,
        }
    }

    componentDidMount() {
        this.setTab()
        this.getClientsDetails()


    }
    // getUser details
    getClientsDetails = () => {
        try {
            if (this.props.match.params)            
            _Api(Url.ADMIN_VIEW_USER.method,Url.ADMIN_VIEW_USER.url,"",this.props.match.params.eId  )
                    .then(resp => {
                        const {
                            folderName,
                            role,
                          

                            _id } = resp.responseData.userData
                        this.setState({
                            typeClient : role , 
                            showUserName: resp.responseData.userData.clientName, isLoadingState: false, folderName
                        })

                    })
                    .catch(err => {

                    })

        } catch (error) {

        }
    }

    // set tab which active or not, on refreshed
    setTab = () => {
        let arr = this.props.location.pathname.split("/")

        if (arr.indexOf("profile") != -1)
            this.setState({ showTab: "Profile" })
        else if (arr.indexOf("project") != -1)
            this.setState({ showTab: "Projects" })
        else if (arr.indexOf("users") != -1)
            this.setState({ showTab: "Users" })

    }
    // handle on click tags
    handleClickTag = (e) => {
        let url = `/clients/${this.props.match.params.id}/${this.props.match.params.eId}/details`
        this.setState({ showTab: e })
        if (e == "Profile")
            this.props.history.push(`${url}/profile`);
        else if (e == "Projects")
            this.props.history.push(`${url}/project`);
        else if (e == "Users")
            this.props.history.push(`${url}/users`);
    }
    render() {
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}
                <SideNav />
                <div className="main-body">
                    <Header
                    />
                    <div className="">
                        <div className="body_header v">
                            <h6 className="headingH6"><Link to="/clients">CLIENTS </Link>
                          
                            <span className="cm_arrow"><Rightarrow/></span>
                            &nbsp;
                            
                            <Link to={{ pathname: `/clients/${this.props.match.params.id}`, state: { state: this.state.folderName } }}   >{this.state.folderName}</Link>&nbsp;
                            <span className="cm_arrow"><Rightarrow/></span>&nbsp;{this.state.showUserName}</h6>

                        </div>

                        <Tabs activeKey={this.state.showTab} id="uncontrolled-tab-example" className="cm_tab" onSelect={this.handleClickTag}>
                            <Tab eventKey="Profile" title="Profile" >
                            </Tab>
                            <Tab eventKey="Projects" title="Projects">
                            </Tab>
                            {
                                this.state.typeClient == 2
                                 &&  <Tab eventKey="Users" title="Users" >
                                </Tab>
                            }
                           
                        </Tabs>
                    </div>
                    <div>
                        <Switch>
                            <Route exact path={"/clients/:id/:eId/details/profile"} component={Profile} />
                            <Route exact path={"/clients/:id/:eId/details/project"} component={Project} />
                            <Route exact path={"/clients/:id/:eId/details/users"} component={Users} />
                            <Route exact path={"/clients/:id/:eId/details/users/:eIdd"} component={UsersEdit} />
                        </Switch>
                    </div>
                </div>
            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        projectInfo: state.projects.projectInfo,
        activeProjectDetails: state.projects.activeProject,
        inActiveProjectDetails: state.projects.inactiveProject,
        totalProjectDetails: state.projects.totalProject,
        dataCount: state.projects.dataCount,
        usersInfo: state.users.userInfo,

    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);

