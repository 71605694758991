

import React, { Component } from 'react';
import SideNav from '../layout/SideNav';
import Header from '../layout/Header';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

import { Link } from 'react-router-dom';

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions

import { addNewResource, getAllResourcesList } from "../../redux/actions/Resources";
import { getAllTags, getAllFolders } from "../../redux/actions/Get";
import { getAllSiteList } from "../../redux/actions/Site";
import { getTagForFIlters } from "../../redux/actions/Tag";
import { resetStore } from "../../redux/actions/Common";

import { Filter } from '../Svg'
// loader
import Loader from "../common/Loader";
// inline error
import InlineError from "../../components/common/InlineError";

// notify
import { notify } from "../../components/common/Toaster";

import AddTag from "../../components/common/add_tag/AddTag"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Select, Icon } from 'antd';

import AddFolder from "../add_folder_common/AddFolderTag";


// url & api services
import Url from "../../config/env/BaseUrl";
import { _Api } from "../../config/api/Api";




var actionArr = []
const { Option } = Select;

class ResourceList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,

            tagId: '',
            showDeleteModal: false,

            isLoadingState: false,
            dataCount: 10,
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,

            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,

            // second

            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError: '',
            emptyFileError: '',

            statusState: 'Delete this resource',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,
            selectAll: false,

            fileUploadFileError: '',
            fileName: 'Choose file...',
            folderIdError: '',
            folderId: '',
            hideInput: '',
            sortTag: '',
            showTagName: 'All Tag',
            showTagColor: '#ffffff',


            dataShow:false
            







        }
    }

    componentDidMount() {

        this.onClickPagenation();
        this.props.action.getAllTags({ status: "1" });
        this.props.action.getAllSiteList({ status: "1" });
        let req = {
            folderType: 5
        }
        this.props.action.getAllFolders(req)
        // this.totalPageCount()
        this.props.action.getTagForFIlters({ tagType: 5 })

    }

    // count pages
    totalPageCount = () => {
        try {

            this.setState({ totalNextPage: Math.floor(this.props.totalUserDetails / this.state.dataCount) })

        } catch (err) {

        }
    }



    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            reportNameError: '',
            reportDescriptionError: '',
            reportUrlError: '',
            emptyFileError: '',


        });
        setTimeout(() => {
            if (this.state.reportUrl != '')
                this.setState({ hideInput: 'file' })
            else if (this.state.fileName == 'Choose file...' && this.state.reportUrl == '')
                this.setState({ hideInput: '' })
        }, 300)
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
    }

    // clear fields
    clearFields = () => {
        this.setState({
            reportName: '',
            reportNameError: '',
            reportDescription: '',
            reportDescriptionError: '',
            reportUrl: '',
            reportFile: '',
            reportProjectId: '',
            reportSiteId: '',
            reportTagId: '',
            deletedId: '',
            reportUrlError: '',
            fileUploadError: '',
            urlError: '',
            statusOk: false,
            emptyFileError: '',
            fileName: 'Choose file...',
            folderIdError: '',
            // folderId:'',
            hideInput: '',




        })

    }
    onImageUpload = e => {
        try {
            let file = e.target.files[0]
            this.setState({ hideInput: 'url', reportFile: file, fileName: file.name, fileUploadFileError: '' }, () => {
                setTimeout(() => {
                    if (this.state.reportUrl != '')
                        this.setState({ hideInput: 'file' })
                    else if (this.state.fileName == 'Choose file...' && this.state.reportUrl == '')
                        this.setState({ hideInput: '' })
                }, 300)
            })
        } catch (err) {

        }
    }


    // on submit form
    handleOnSubmitForm = (e) => {
        const UrlRegx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
        if (this.state.reportName == "") {
            this.setState({ reportNameError: "*Please enter resource name." })
        }
        else if (this.state.reportDescription == "") {
            this.setState({ reportDescriptionError: "*Please enter description.." })
        }



        else if (this.state.reportUrl != "" && !UrlRegx.test(this.state.reportUrl)) {
            this.setState({ reportUrlError: "*Please enter valid url." })
        }
        else if (this.state.reportUrl == "" && this.state.reportFile == '') {
            this.setState({ emptyFileError: "*Please enter url or add a file" })
        }



        else if (this.state.reportUrl == "" && !this.state.reportFile) {
            this.setState({ fileUploadFileError: "*Please add a file" })
        }

        else if (this.state.reportFile && this.state.reportFile.type.split("/")[0] != "text" && this.state.reportFile.type.split("/")[0] != "application") {


            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile && this.state.reportFile.type.split("/")[1] == "exe" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage") {


            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }
        else if (this.state.reportFile && this.state.reportFile.type.split("/")[1] == "zip" || this.state.reportFile.type == "application/x-ms-dos-executable" || this.state.reportFile.type == "application/x-iso9660-appimage" || this.state.reportFile.type == "application/vnd.android.package-archive") {

            this.setState({ fileUploadFileError: "*Please add a valid file.", })
        }




        else if (this.state.folderId == "") {
            this.setState({ folderIdError: "*Please select folder" })
        }


        else {

            let formData = new FormData()
            formData.append("resourceName", this.state.reportName);
            formData.append("desc", this.state.reportDescription)
            formData.append("folder", this.state.folderId)
            formData.append("url", this.state.reportUrl);
            formData.append("project", this.props.match.params.id)
            formData.append("tag", this.state.reportTagId)
            formData.append("fileData", this.state.reportFile)


            let req2 = {
                project: this.props.match.params.id,
                search: this.state.searchString,
                status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
                count: String(this.state.dataCount),
                sortType: this.state.sortType,
                sortField: this.state.sortField,
                pageNo: String(this.state.pageNo),
                tag: this.state.sortTag,
                tagType: 5,
                folder: '',
            }


            if (e.target.name == "save") {

                this.props.action.addNewResource(formData, this.props.match.params.id, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewResource(formData, this.props.match.params.id, req2)
                this.clearFields();
            }
        }
    }

    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this resource" : status == 0 ? "Inactive this resource" : "delete this resource" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }
    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            resourceId: [actionArrr].toString()
        }

        _Api(Url.ADMIN_CHANGE_STATUS_RESOURCE.method, Url.ADMIN_CHANGE_STATUS_RESOURCE.url, req )
            .then(resp => {
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })
                actionArr = []
                notify("success", resp.responseData.message)
                this.onClickPagenation();


            }).catch(err => {
                this.setState({ isLoadingState: false, showDeleteModal: false, deletTagId: "", selectAll: false })
                actionArr = []

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditUser = (resource) => {
        this.props.history.push(`/projects/edit/${this.props.match.params.folderId}/${this.props.match.params.id}/resource/${resource._id}`, { state: resource });

    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {



        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })

        }
        if (page == 1 && (this.props.resourceData) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {

            project: this.props.match.params.id,
            search: this.state.searchString,
            status: this.state.activeClass == "t" ? "" : this.state.activeClass == "a" ? "1" : "0",
            count: String(this.state.dataCount),
            sortType: this.state.sortType,
            sortField: this.state.sortField,
            pageNo: String(this.state.pageNo),
            tag: this.state.sortTag
        }

        this.props.action.getAllResourcesList(req);
    }


    // on search
    handleOnSearch = (e) => {

        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }

    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click
    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "report") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'resourceName' }, () => {

                this.onClickPagenation()
            })

        }

        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = (e) => {
        try{
         if(e.target.name == "folder")
         this.clickChild1()
         else if(e.target.name == "tag")
         this.clickChild()
         this.setState({ inviteNewPopupOpen: false })
        }
        catch(err){}
 
     }

    handleChecked = (e) => {
        let num = e.target.value
        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }
    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
 


    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these resources" : status == 0 ? "Inactive these resources" : "delete these resources" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }

    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.resourceList &&
                    this.props.resourceList.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }


    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }
    sortByTag = (item) => {

        this.setState({
            sortTag: item._id,
            showTagName: item.tagName,
            showTagColor: item.color
        }, () => this.onClickPagenation())

    }


   // to re set folder & re set store that pre data not show
   componentWillUnmount() {
    this.props.action.resetStore();
}

    render() {
        // this.totalPageCount() 
        var pageRange = Math.ceil(this.props.resourceData / this.state.dataCount)
        setTimeout(()=> this.setState({dataShow:true}), 2000)
        return (
            <>
                {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}



                <div className="body_header">
                    {/* <h6 className="headingH6"> <Link to="/resources/folders">Resources</Link> &nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i>&nbsp; {this.props.location.state && this.props.location.state.state}</h6> */}

                    <div className="new_btn text-right btn_parent">
                        <div className="btn_child">
                            <Dropdown className="filter_dropdown1 btn btn-sm" alignRight>
                                <Dropdown.Toggle className="ext-css" variant="success" id="dropdown-basic"  >
                                    <>

                                        <div style={{ backgroundColor: this.state.showTagColor }} className="filter_dropdown_option"></div>
                                        &nbsp;&nbsp;
                                <div className="tagText">{this.state.showTagName}</div>
                                    </>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="action_menu" alignRight>
                                    <>
                                        <Dropdown.Item onSelect={() => this.sortByTag({ _id: '', tagName: 'All Tag', color: '#ffffff' })}  >
                                            <div className="filter_dropdown_parent" >
                                                {/* <div style={{ backgroundColor: 'white' }} className="filter_dropdown_option"></div> */}
                                                &nbsp;&nbsp;
                                                <div>All Tag
                                                </div>
                                            </div>

                                        </Dropdown.Item>

                                    </>
                                    {
                                        this.props.filterTagData && this.props.filterTagData.map((item, index) => (
                                            <>
                                                <Dropdown.Item  >
                                                    <div className="filter_dropdown_parent" onClick={() => this.sortByTag(item)}>

                                                        <div style={{ backgroundColor: item.color }} className="filter_dropdown_option"></div>
                                                        &nbsp;&nbsp; <div>{item.tagName}</div>
                                                    </div>

                                                </Dropdown.Item>

                                            </>

                                        ))
                                    }

                                </Dropdown.Menu>
                            </Dropdown>
                            <button className="btn btn-primary btn-sm btn-blue" onClick={this.openInviteNewPopupOpen}>Add New</button>
                            &nbsp; &nbsp; <button className="btn btn-primary btn-sm btn-blue" onClick={() => this.props.history.push("resource/data")}>Import</button>
                        </div>
                    </div>
                </div>
                <div className="counter_detail">

                    {
                        actionArr && actionArr.length != 0 &&

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="action_dropdown">
                                {actionArr.length} Select
                          </Dropdown.Toggle>

                            <Dropdown.Menu className="action_menu">
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "1")}>Active</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "0")}>Inactive</Dropdown.Item>
                                <Dropdown.Item onSelect={() => this.handleDeleteModal2(actionArr, "2")}>Delete</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                </div>


                <div className="table-responsive">
                    <table className="table table-condensed">
                        <thead>
                            <tr>
                                <th> <input type="checkbox" className="checkbox" name="time" value={"test"} checked={this.state.selectAll} onClick={this.handleAllSelect} /></th>
                                <th>Sr No.</th>
                                <th onClick={() => this.handleOnClickSort("report")}>Resource Name<span className="sort_icon"><i className={`fa fa-sort-alpha-${this.state.sortToggleName ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                <th>Created By </th>
                                <th>Type</th>
                                <th onClick={() => this.handleOnClickSort("createdAt")} >Created At <span className="sort_icon"><i class={`fa fa-sort-numeric-${this.state.sortToggleCreated ? "asc" : "desc"}`} aria-hidden="true"></i></span></th>
                                <th>Tag</th>
                                <th>Action</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>

                            {

                                this.props.resourceList &&
                                this.props.resourceList.map((resource, index) => (
                                    <tr key={index}>
                                        <td><input type="checkbox" className="checkbox" name="time" value={resource._id} checked={actionArr.indexOf(resource._id) != -1} onClick={this.handleChecked} /></td>
                                        <td>{index + 1 + this.state.pageNo * this.state.dataCount}</td>
                                        <td className="txtClassUpper">{`${resource.resourceName}`}</td>
                                        {/* <td><Link to={`/resource-profile/${this.props.match.params.id}/${resource._i}` }>{`${resource.firstName} ${resource.lastName}`}</Link></td> */}
                                        <td>{"Admin"}</td>
                                        <td>{resource.fileType}</td>
                                        <td>{dateFormat(resource.created, "mm/dd/yyyy")}</td>
                                        <td>
                                            {
                                                resource.tagName == undefined ?
                                                    <>
                                                        <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                        &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                        &nbsp;
                                                                                <i class="fa fa-window-minimize" aria-hidden="true">
                                                        </i>
                                                    </>
                                                    :
                                                    <div class="display-flex">
                                                        <div style={{ backgroundColor: resource.color }} class="color_circle"></div>
                                                        &nbsp;&nbsp;
                                                                                <div>{resource.tagName}</div>
                                                    </div>
                                            }
                                        </td>
                                        <td>
                                            <div>
                                                <button type="button" className="btn btn-primary btn-sm btn-blue" onClick={() => this.onClickEditUser(resource)}>Edit</button>&nbsp;&nbsp;&nbsp;
                                                    <button type="button" className="btn btn-primary btn-sm btn-pink" onClick={() => this.handleDeleteModal(resource._id, "2")}>Delete</button> &nbsp;&nbsp;&nbsp;


                                                  {
                                                    resource.fileType == "url" ? <CopyToClipboard text={resource.fileData}
                                                        onCopy={() => notify("success", "copied")}>
                                                        <button className="btn  btn-sm btn-default"   >Copy</button>
                                                    </CopyToClipboard> :
                                                        <a href={resource.fileData} className="btn  btn-sm btn-default" download>Export</a>
                                                }


                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" onClick={() => this.handleDeleteModal(resource._id, resource.status == "1" ? "0" : "1")} class={`btn btn-toggle ${resource.status == "1" ? "active" : ""}`} data-toggle="button" aria-pressed="true" autocomplete="off">
                                                <div class="handle"></div>
                                            </button>
                                        </td>


                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                    {
                        this.state.dataShow  && this.props.resourceData == 0 && this.props.isLoading != true && <p style={{ display: 'flex', justifyContent: 'center' }} className="cm_center_text" > Sorry no more content </p>
                    }



                    <div className="float-right">
                        {this.props.resourceData >= 10 &&
                            <div class="pagination">
                                <button class="page-item" onClick={() => this.onClickPagenation(-1)} disabled={this.state.pageNo == 0} ><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                                <p>{this.state.pageNo + 1} to {pageRange == 0 ? 1 : pageRange}</p>
                                <button class="page-item" onClick={() => this.onClickPagenation(1)} disabled={Math.ceil(this.props.resourceData / this.state.dataCount) <= this.state.pageNo + 1} ><i class="fa fa-chevron-right" aria-hidden="true" ></i></button>

                            </div>

                        }
                    </div>
                </div>


                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />
                 <AddFolder
                  folderType ={5}
                  setClick={click => this.clickChild1 = click}
                  closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />



                <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal" centered>

                    <Modal.Header closeButton>
                        {/* <Modal.Title>Modal heading</Modal.Title> */}
                        <h6>Add New</h6>
                    </Modal.Header>

                    <Modal.Body>
                        <form onSubmit={this.handleOnSubmitForm}>


                            <div class="form-group">
                                <label for="email">Resource Name</label><span className="impFields">*</span>
                                <input
                                    type="text"
                                    className={`form-control ${this.state.reportNameError != '' ? 'is-invalid' : ''}`}
                                    id="reportName"
                                    name="reportName"
                                    placeholder="Enter resource Name"
                                    maxLength={30}
                                    value={this.state.reportName}
                                    autocomplete="off"
                                    onChange={this.handleOnChange}
                                    required

                                />
                                <InlineError
                                    message={this.state.reportNameError}
                                />
                            </div>


                            <div class="form-group">
                                <label for="email">Description</label><span className="impFields">*</span>
                                <textarea rows="4" cols="50"
                                    type="text"
                                    className={`form-control ${this.state.reportDescriptionError != '' ? 'is-invalid' : ''}`}
                                    id="reportDescription"
                                    placeholder="Enter description"
                                    maxLength={256}
                                    name="reportDescription"
                                    autocomplete="off"
                                    required
                                    value={this.state.reportDescription}
                                    onChange={this.handleOnChange}

                                />
                                <InlineError
                                    message={this.state.reportDescriptionError}
                                />
                            </div>



                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">URL</label><span className="impFields">*</span><br />
                                    <input
                                        disabled={this.state.hideInput == "url"}
                                        type="url"
                                        className={`form-control ${this.state.reportUrlError != '' ? 'is-invalid' : ''}`}
                                        id="reportUrl"
                                        placeholder="Enter url"
                                        maxLength={256}
                                        name="reportUrl"
                                        autocomplete="off"
                                        required
                                        value={this.state.reportUrl}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.reportUrlError}
                                    />

                                </div>
                            </div>



                            <div class="form-group row df-end">
                                <div className="col-md-12">
                                    <InlineError
                                        message={this.state.emptyFileError}
                                    />
                                    <label className="text-center display-b" style={{ height: 'auto' }}>OR</label>
                                    <label for="email">Resource file</label><span className="impFields">*</span><br />
                                    <div class="custom-file">
                                        <input disabled={this.state.hideInput == "file"} id="inputGroupFile01" onChange={this.onImageUpload} type="file" className={`custom-file-input  ${this.state.fileUploadFileError != '' ? 'is-invalid' : ''}`}

                                            accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        />
                                        <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
                                    </div>

                                    <InlineError
                                        message={this.state.fileUploadFileError}
                                    />

                                </div>
                            </div>
                            <div class="form-group row df-end">
                                <div className="col-md-8">
                                    <label for="email">Folder</label><span className="impFields">*</span><br />
                                    <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                        {
                                            this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                <Option value={item._id}>
                                                    <div class="dropdown_flex">
                                                        <div><img src={item.icon} /></div>
                                                        <div><p>{item.folderName}</p></div>
                                                    </div>
                                                </Option>

                                            ))
                                        }

                                    </Select>
                                    <InlineError
                                        message={this.state.folderIdError}
                                    />

                                </div>
                                <div className="col-md-4"><button type="button" name="folder" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Folder</button></div>

                            </div>
                            <div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="reportTagId" onChange={this.handleOnChange}>
                                            {this.state.reportTagId == "" && <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>}
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option className="txtClassUpper" key={index} value={item._id}>{item.tagName}</option>
                                                    )
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" name="tag" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <br />
                                <div class="form-group">
                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Save & Add New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Save</button>
                                </div>
                            </div>

                        </form>
                    </Modal.Body>

                </Modal>

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal} className="custom_modal">
                    <Modal.Header closeButton>
                        <h6>Resource Action</h6>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <button class="btn btn-default  btn-sm mR-20" onClick={this.handleDeleteModal}>
                            No
          </button>
                        <button variant="primary" class="btn btn-primary btn-sm btn-blue " onClick={this.handleActionModal}>
                            Yes
          </button>
                    </Modal.Footer>
                </Modal>
            </>
        );

    }
}

// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        siteInfo: state.sites.siteInfo,
        resourceList: state.resources.resourceInfo,
        resourceActive: state.resources.activeresource,
        resourceInactive: state.resources.inactiveResource,
        resourceTotal: state.resources.totalResource,
        resourceData: state.resources.dataCount,
        foldersList: state.folders.folderList,
        filterTagData: state.tags.filterInfo,
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getTagForFIlters, getAllFolders, getAllTags, addNewResource, getAllResourcesList, getAllSiteList }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList);

