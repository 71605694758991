import React, { Component } from 'react';
import { Dropdown, Modal, Button, ModalFooter, ModalBody } from 'react-bootstrap';
import dateFormat from "dateformat"

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// actions
import { getAllUserList, addNewUser } from "../../../redux/actions/User";
import { getAllTags, getAllFolders } from "../../../redux/actions/Get";
import {  getAllProjectList } from "../../../redux/actions/Project";
import { resetStore } from "../../../redux/actions/Common";
// loader
import Loader from "../../common/Loader";
// inline error
import InlineError from "../../../components/common/InlineError";
// validation
import { validateEmail, validateMobileNo1 } from "../../../utils/Validation";

// services 
import { _actionForUser, _checkEmailAvaliblity } from "../../../config/api/UserService";

// notify
import { notify } from "../../../components/common/Toaster";

import AddTag from "../../../components/common/add_tag/AddTag"
import { Select, Icon } from 'antd';



var actionArr = [];
const { Option } = Select;

class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inviteNewPopupOpen: false,
            userEmail: '',
            userEmailError: '',
            userLastName: '',
            userFirstName: '',
            userLastNameError: '',
            userFirstNameError: '',
            userPhoneNumber: '',
            userPhoneNumberError: '',
            userRole: '1',
            tagId: '',
            showDeleteModal: false,
            deletUserId: '',
            isLoadingState: false,
            dataCount: "10",
            searchString: '',
            statusSearch: '',
            sortType: '',
            sortField: '',
            pageNo: 0,
            totalpage: '',
            activeClass: 't',
            totalNextPage: 1,
            isTagPopup: false,
            emailAvaliblity: true,
            emailAvaliblityMessage: '',


            sortToggleName: true,
            sortToggleEmail: true,
            sortToggleCreated: true,
            statusState: 'Delete this user',
            StatusAction: '',
            value: '',
            copied: false,
            FilterStatus: false,

            toggle: false,

            selectAll: false,

            folderIdError:'',
            folderId:'',





        }
    }

    componentDidMount() {

        this.onClickPagenation()
        this.props.action.getAllTags({status:"1"});
        this.props.action.getAllProjectList({status:"1"});
        let req = {
            folderType: 4
        }
        this.props.action.getAllFolders(req)

        this.props.setClick(this.openInviteNewPopupOpen);


    }



    countEmailAvaliblity = (email) => {
        _checkEmailAvaliblity({ email })
            .then(resp => {

                this.setState({ emailAvaliblity: false, emailAvaliblityMessage: '' })

            }).catch(err => {

                this.setState({ emailAvaliblity: true, emailAvaliblityMessage: err.error.message })


            })
            .catch(err => { })
    }



    //handle on handleOnChange
    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' });
        if (e.target.name == "userEmail")
            this.countEmailAvaliblity(e.target.value)
    }


    openInviteNewPopupOpen = () => {
        this.setState({
            inviteNewPopupOpen: !this.state.inviteNewPopupOpen
        })
    }

    //close modal
    closeModal = () => {
        this.setState({
            inviteNewPopupOpen: false,
        })
        this.clearFields()
        this.props.closeModalAddTag && 
        this.props.closeModalAddTag(true)
    }

    // clear fields
    clearFields = () => {
        this.setState({ userFirstName: '', userLastName: '', userEmail: '', userPhoneNumber: '', userEmailError: '', userLastNameError: '', userFirstNameError: '', userPhoneNumberError: '' })

    }

    // on submit form
    handleOnSubmitForm = (e) => {
        if (this.state.userFirstName == "") {
            this.setState({ userFirstNameError: "*Please enter first name." })
        }
        else if (this.state.userLastName == "") {
            this.setState({ userLastNameError: "*Please enter last name." })
        }

        else if (this.state.userPhoneNumber == "") {
            this.setState({ userPhoneNumberError: "*Please enter phone number." })
        }
        else if (this.state.userEmail == "") {
            this.setState({ userEmailError: "*Please enter email." })
        }
        else if (!validateEmail(this.state.userEmail).status) {
            this.setState({ userEmailError: "*Please enter valid email." })


        }
       
        else if (this.state.emailAvaliblity) {
            this.setState({ userEmailError: this.state.emailAvaliblityMessage })


        }
        else if (this.state.userPhoneNumber == "") {
            this.setState({ userPhoneNumberError: "*Please enter phone number." })
        }
        else if (!validateMobileNo1(this.state.userPhoneNumber).status) {
            this.setState({ userPhoneNumberError: "*Please enter valid phone number." })
        }
        else if (this.state.folderId == "") {
            this.setState({ folderIdError: "*Please select folder" })
        }
        else {

            let req = {
                firstName: this.state.userFirstName,
                lastName: this.state.userLastName,
                email: this.state.userEmail,
                phoneNumber: this.state.userPhoneNumber,
                role: "1",
                folder: this.state.folderId,    
                tags: this.state.tagId,
            }

            let req2 = {
        

            
            }


            if (e.target.name == "save") {

                this.props.action.addNewUser(req, req2)
                this.closeModal()
                this.clearFields()

            }
            else {

                this.props.action.addNewUser(req, req2)
                this.clearFields();
            }
        }
    }
    // handle delete modal
    // handle delete modal
    handleDeleteModal = (id, status) => {
        actionArr = [];
        this.setState({ statusState: status == 1 ? "Active this user" : status == 0 ? "Inactive this user" : "delete this user" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            if (id) {
                this.setState({ deletTagId: id, StatusAction: status });
                actionArr.push(id)

            }
        }

        )
    }


    handleActionModal = () => {
        this.onUserAction(actionArr, this.state.StatusAction)
    }

    // on click yes to delete user handle , 
    // 1 for active
    // 0 for inactive
    // 2 for delete
    onUserAction = (actionArrr, statusCode) => {
        this.setState({ showDeleteModal: false, isLoadingState: true })
        let req = {
            status: statusCode,
            activeId: [actionArrr].toString()
        }

        _actionForUser(req)
            .then(resp => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, deletUserId: '', selectAll: false })

                notify("success", resp.responseData.message)
                this.onClickPagenation()


            }).catch(err => {
                actionArr = []
                this.setState({ isLoadingState: false, showDeleteModal: false, selectAll: false })

                if (err.error)
                    notify("err", err.error.message)
                else if (err)
                    notify("err", err.message)



            })

    }



    //edit user
    onClickEditUser = (user) => {
        this.props.history.push(`/sites/edit/${this.props.match.params.folderId}/${"this.props.match.params.id"}/client/${user._id}`, { state: user });

    }


    onChangeTabStstus = (tab) => {
        this.setState({ activeClass: tab, pageNo: 0 }, () => this.onClickPagenation())


    }



    // on click pagenation 
    // on search
    // 1 more
    onClickPagenation = (page) => {

        if (page == -1 && this.state.pageNo > 0) {
            this.setState({ pageNo: --this.state.pageNo })
        }

        if (page == 1 && (Math.ceil(this.props.dataCount / this.state.dataCount)) >= this.state.pageNo)
            this.setState({ pageNo: ++this.state.pageNo })

        let req = {    
           
        }

        this.props.action.getAllUserList(req);
    }


    // on search
    handleOnSearch = (e) => {
        if (e.target.value.length >= 3)
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())
        else if (e.target.value.length == "")
            this.setState({ searchString: e.target.value, pageNo: 0 }, () => this.onClickPagenation())


    }


    // on click

    handleOnClickSort = (e) => {
        // firstName,lastName,email,password,countryCode,phoneNumber,
        // role,comName,address,site,folder,profilePic,tags,status,
        // created,updated
        if (e == "firstName") {
            this.setState({ sortToggleName: !this.state.sortToggleName, sortType: this.state.sortToggleName ? '-1' : '1', sortField: 'firstName' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "email") {
            this.setState({ sortToggleEmail: !this.state.sortToggleEmail, sortType: this.state.sortToggleEmail ? '-1' : '1', sortField: 'email' }, () => {

                this.onClickPagenation()
            })

        }
        else if (e == "createdAt") {
            this.setState({ sortToggleCreated: !this.state.sortToggleCreated, sortType: this.state.sortToggleCreated ? '1' : '-1', sortField: 'created' }, () => {

                this.onClickPagenation()
            })

        }

    }

    handleAddTag = () => {
        this.clickChild()
        this.setState({ inviteNewPopupOpen: false })

    }

  


    handleChecked = (e) => {
        let num = e.target.value

        if (actionArr.indexOf(num) == -1) {
            actionArr.push(num)
            this.setState({ toggle: !this.state.toggle })

        }
        else {
            let arr =
                actionArr = actionArr.filter(item => item != num)
            this.setState({ toggle: !this.state.toggle })
        }


    }

    toggleFilterStatus = () => {

        this.setState({ FilterStatus: !this.state.FilterStatus })

        // document.addEventListener('click', this.handleClickOutside);

    }
    

    handleDeleteModal2 = (id, status) => {
        if (actionArr.length == 0) return
        this.setState({ StatusAction: status, statusState: status == 1 ? "Active these users" : status == 0 ? "Inactive these users" : "delete these users" }, () => {

            this.setState({ showDeleteModal: !this.state.showDeleteModal })
            // if (id){
            //     this.setState({ deletTagId: id, : status });

            // }
        }

        )
    }


    handleAllSelect = () => {
        try {
            if (!this.state.selectAll) {
                actionArr = []
                this.props.usersInfo &&
                    this.props.usersInfo.forEach(item => actionArr.push(item._id))

                this.setState({ selectAll: true })

            }
            else {
                actionArr = []
                this.setState({ selectAll: false })

            }


        } catch (err) {

        }

    }

    // on change folder select
    handleChange = (value) => {
        this.setState({ folderId: value, folderIdError: '' });
    }

    componentWillUnmount() {
        this.props.action.resetStore();
    }



    render() {

        var pageRange = Math.ceil(this.props.dataCount / this.state.dataCount)

        return (
            <>
                {/* {this.props.isLoading && <Loader /> || this.state.isLoadingState && <Loader />}    */}
                <AddTag
                    setClick={click => this.clickChild = click}
                    closeModalAddTag={() => this.setState({ inviteNewPopupOpen: true })}
                />          

            
                    <Modal show={this.state.inviteNewPopupOpen} onHide={this.closeModal} className="custom_modal">
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                            <h6>Invite New</h6>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={this.handleOnSubmitForm}>
                               
                                <div class="form-group">
                                    <label for="email">First Name</label><span className="impFields">*</span>
                                    <input
                                        type="text"
                                        className={`form-control ${this.state.userFirstNameError != '' ? 'is-invalid' : ''}`}
                                        id="userFirstName"
                                        name="userFirstName"
                                        placeholder="First Name"
                                        maxLength={20}
                                        value={this.state.userFirstName}
                                        autocomplete="off"
                                        onChange={this.handleOnChange}
                                        required

                                    />
                                    <InlineError
                                        message={this.state.userFirstNameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Last Name</label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userLastNameError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        placeholder="Last Name"
                                        maxLength={20}
                                        name="userLastName"
                                        autocomplete="off"
                                        required
                                        value={this.state.userLastName}
                                        onChange={this.handleOnChange}

                                    />
                                    <InlineError
                                        message={this.state.userLastNameError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Email</label><span className="impFields">*</span>
                                    <input
                                        type="email"
                                        className={`form-control ${this.state.userEmailError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        maxLength={50}
                                        placeholder="Email"
                                        autocomplete="off"

                                        required
                                        name="userEmail"
                                        value={this.state.userEmail}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userEmailError}
                                    />
                                </div>
                                <div class="form-group">
                                    <label for="email">Phone Number</label><span className="impFields">*</span>
                                    <input type="tel"
                                        className={`form-control ${this.state.userPhoneNumberError != '' ? 'is-invalid' : ''}`}
                                        id="email"
                                        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                        required
                                        maxLength={20}
                                        placeholder="Phone Number"
                                        name="userPhoneNumber"
                                        autocomplete="off"
                                        value={this.state.userPhoneNumber}
                                        onChange={this.handleOnChange}
                                    />
                                    <InlineError
                                        message={this.state.userPhoneNumberError}
                                    />
                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Folder</label><span className="impFields">*</span><br />
                                        <Select defaultValue="Select folder" onChange={this.handleChange} className="arrow">
                                            {
                                                this.props.foldersList && this.props.foldersList.map((item, index) => (
                                                    <Option value={item._id}>
                                                        <div class="dropdown_flex">
                                                            <div><img src={item.icon} /></div>
                                                            <div><p>{item.folderName}</p></div>
                                                        </div>
                                                    </Option>

                                                ))
                                            }

                                        </Select>
                                        <InlineError
                                            message={this.state.folderIdError}
                                        />

                                    </div>
                                    {/* s<div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div> */}

                                </div>
                                <div class="form-group row df-end">
                                    <div className="col-md-8">
                                        <label for="email">Tag</label>
                                        <select class="form-control" id="sel1" name="tagId" onChange={this.handleOnChange}>
                                            <option key={-1} value={"select"} disabled selected hidden  >{"Select tag"}</option>
                                            {
                                                this.props.tagsList && this.props.tagsList.map((item, index) =>
                                                    (
                                                        <option key={index} value={item._id}>{item.tagName}</option>
                                                    )




                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-4"><button type="button" class="btn btn-primary btn-blue P1016 col-sm-12" onClick={this.handleAddTag}>Add Tag</button></div>

                                </div>
                                <br />
                                <div class="form-group">
                                    <button type="button" class="btn btn-default mR-20" onClick={this.handleOnSubmitForm}>Invite & Invite New</button>
                                    <button type="button" name="save" class="btn btn-primary btn-blue " onClick={this.handleOnSubmitForm}>Invite</button>
                                </div>

                            </form>
                        </Modal.Body>
                    </Modal>
            

                <Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>User Action</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want {this.state.statusState} ? </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleDeleteModal}>
                            No
          </Button>
                        <Button variant="primary" onClick={this.handleActionModal}>
                            Yes
          </Button>
                    </Modal.Footer>
                </Modal>

            </>
        );

    }
}


// get store data
const mapStateToProps = state => {
    return {
        isLoading: state.loading.isLoading,
        isLoggedIn: state.login.isLoggedIn,
        tagsList: state.tags.tagInfo,
        usersInfo: state.users.userInfo,
        activeUserDetails: state.users.activeUser,
        inActiveUserDetails: state.users.inactiveUser,
        totalUserDetails: state.users.totalUser,
        dataCount: state.users.dataCount,
        foldersList: state.folders.folderList,
        
    }
}

// dispatch action
const mapDispatchToProps = dispatch => {
    return {
        action: bindActionCreators({resetStore, getAllProjectList, getAllUserList, getAllTags, addNewUser, getAllFolders }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);

