import {
    IS_LOADING_START,
    IS_LOADING_STOP,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    IS_LOGGED_IN,
    IS_LOGGED_OUT
} from "../actions/Types";

import redexState from "./InitalState";





// login
export const loading = (state = redexState.commonInitalState, action) => {
    switch (action.type) {
        case IS_LOADING_START:
            return {
                ...state,
                isLoadingStatus:state.isLoadingStatus+1,
                isLoading: true,
            }

        case IS_LOADING_STOP:
            return {
                ...state,
                isLoadingStatus:state.isLoadingStatus-1,
                isLoading: false,
            }

        default:
            return {...state}
    }

}